import { styled } from '@linaria/react';
import { StyledLandingSection } from './LandingSection';
export const LandingNotImplementedYet = () => {
  return <StyledLandingSection className="productions " bg={'var(--clr-nav-shadow)'}>
			<div className="content">
				<div className="headline">
					<h2>Coming Soon</h2>
					<p>Sorry, we're still finishing this page up. Check back again soon.</p>
				</div>
			</div>
		</StyledLandingSection>;
};